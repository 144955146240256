import React, {useState} from 'react';
import {sortBy} from './helpers';

export default ( { uppdragshistorik } ) => {

    const [filterBy, setFilterBy] = useState(null)
    console.log(filterBy)
    
    const { uppdrag } = uppdragshistorik
    const years = uppdrag
                    .map( item => item.tid.substring(0,4) )
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort( ( a, b ) => a > b ? -1 : 1);

    const getClasses = ( arr ) => arr.map( item => `item-${item}` ).join( " " );

    const getProjectsForYear = year => {
        return uppdrag
                .map( ( item, index ) => {
                    if ( item.tid.substring(0,4) === year ) {
                        return <a href={`#uppdrag-${index}`} 
                                  key={`project-${year}-${index}`} 
                                  onClick={() => console.log(`project-${year}-${index}`)} 
                                  title={item.projekt} 
                                  className={`project-item ${getClasses(item.roller) } ${getClasses(item.roller).indexOf('item-'+filterBy) >= 0 ? 'active' : '' }`}></a>
                    }
                    return false;
                } )
                .filter(Boolean)
    }

    console.log(uppdragshistorik)
    return (<section id="uppdragshistorik" className={`uppdragshistorik ${filterBy !== null ? 'active' : ''}`}>
        <h3>Uppdragshistorik</h3>
        <section className={`uh ${filterBy !== null ? 'active' : ''}`}>
          <div className="roles">
            <span onClick={() => setFilterBy( filterBy !== 0 ? 0 : null )} className={`role ${filterBy === 0 ? 'active' : ''}`}><span className={`project-item item-0`} /><span className="description">Analys</span></span>
            <span onClick={() => setFilterBy( filterBy !== 1 ? 1 : null)} className={`role ${filterBy === 1 ? 'active' : ''}`}><span className={`project-item item-1`} /><span className="description">Design</span></span>
            <span onClick={() => setFilterBy( filterBy !== 2 ? 2 : null)} className={`role ${filterBy === 2 ? 'active' : ''}`}><span className={`project-item item-2`} /><span className="description">Code</span></span>
            <span onClick={() => setFilterBy( filterBy !== 3 ? 3 : null)} className={`role ${filterBy === 3 ? 'active' : ''}`}><span className={`project-item item-3`} /><span className="description">Mentor</span></span>
          </div>
          { years.map(item => (<div key={`year-${item}`} className="year"><b>{item}</b>{getProjectsForYear(item)}</div>) ) }
        </section>
        {uppdragshistorik.uppdrag.sort(sortBy("tid")).map( (u, index) => 
            <div key={u.projekt+"-"+index} className={`uppdrag ${u.roller.indexOf(filterBy) >= 0 ? 'active' : '' }`} id={"uppdrag-"+index}>
                <h4>{u.projekt}{u.kund && (<i>, {u.kund}</i>)}</h4>
                <p className="lightText">{u.tid}, {u.roller.map( roll => uppdragshistorik.roller[roll] ).join(', ') }</p>
                {u.besk && (<p>{u.besk}</p>)}
                {u.metod && (<p>Metod: {u.metod}</p>)}
            </div>
        )}
    </section>)
}