import React, { Component } from 'react';
import firebase from './firebase.js';

import './App.css';
import Head from './components/header';
import Anstallning from './components/anstallning';
import Kompetens from './components/kompetens';
import Utbildning from './components/utbildning';
import Uppdragshistorik from './components/uppdragshistorik';

class App extends Component {
    constructor(props) {
        super(props);
        const key = window.location.pathname.substr(1)
        this.state = {
            key,
            timeout: key === ""
        };
        window.setTimeout(() => this.setState( { timeout : true } ), 5000)
    }

    componentDidMount() {
        if (this.state.key !== "" ) {
            this.firebaseRef = firebase.database().ref( this.state.key );
            this.firebaseCallback = this.firebaseRef.on('value', (snap) => {
                this.setState({ ...snap.val() });
            });
        } 
    }
      
    componentWillUnmount() {
        this.firebaseRef.off('value', this.firebaseCallback);
    }
    
    render() {
        let content = null;
        if (this.state.meritforteckning) {
            const { generellt, anstallningshistorik, kompetens, utbildning, uppdragshistorik } = this.state.meritforteckning;
            const translation = this.state.texts;
            content = (
                <div className="meritforteckning">
                    <Head generellt={generellt} />
                    <Anstallning translation={translation} anstallningshistorik={anstallningshistorik} />
                    <Kompetens translation={translation} kompetens={kompetens} />
                    <Utbildning translation={translation} utbildning={utbildning} />
                    <Uppdragshistorik translation={translation} uppdragshistorik={uppdragshistorik} />
                </div>
            );
        } else if ( !this.state.timeout ) {
            content = (
                <div className="WaitingForData">
                    <h1>Please hold for data...</h1>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            );
        } else {
            const generellt = {
              namn : "Frank Lood",
              gata: "Rosenvingegatan 2",
              stad: "Mölndal",
              postnummer: "431 63",
              company: "Fralo Design & Development AB",
              companyNumber: "559206-6012",
              telenr: "‭079-340 63 18‬",
              epost: "frank@fralo.se",
              desc: [
                "A happy freelance developer and designer. Who changes the world one UI at a time; by writing code that makes users smile for more than 20 years.",
                "For more info, or a full detailed cv <a href='mailto:me@fralo.se?subject=Love your site, please give me a key so i can see the rest!'>send me a mail</a> and i'll get back to you. Or why not become friends on <a href='http://www.linkedin.com/in/franklood'>the LinkedIn thingy</a>.",
                "Take care! &lt;3",
                "/Frank"
              ]
            }
            content = (
                <div className="NoDataComing">
                  <Head generellt={generellt} />
                </div>
            );
        }
        return <div className="TheContainer">
          <div id="logo" />
          {content}
        </div>
    }
}

export default App;
