import React from 'react';
export default ({translation, utbildning}) => (
    <section className="utbildning">
        <h3>{translation.utbildning}</h3>
        <div className="utb">
            <h4>{utbildning.linje}</h4>
            {utbildning.skola}, {utbildning.ort}, {utbildning.fom} - {utbildning.tom}
        </div>
        <h4>{translation.kurser}</h4>
        <ul>
            {utbildning.kurser.map( item => <li key={item.kurs}>{item.kurs}, {item.leverantor},<br /> {item.datum}</li>)}
        </ul>
        <h4>{translation.sprak}</h4>
        <ul>
            {utbildning.sprak.map( item => <li key={item.sprak}>{item.sprak}, {item.niva}</li>)}
        </ul>
    </section>
)