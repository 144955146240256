import React from 'react';
import {sortBy} from './helpers';

export default ({translation, anstallningshistorik}) => (
    <section className="anstallningshistorik">
        <h3>{translation.anstallningshistorik}</h3>
        <ul>
            {anstallningshistorik.sort(sortBy("fom")).map(item => <li key={item.fom}>{item.foretag} <b>{item.roll}</b> <span className="dateRange">{item.fom} - {item.tom}</span></li>)}
        </ul>
    </section>
)