import React from 'react';
export default ({generellt}) => (
    <header className="huvud">
        <div className="me">
            <h1 className="namn">{generellt.namn}</h1>
            {generellt.persnr && <h2 className="personnummer">{generellt.persnr}</h2>}
            <div className="adr">
                <div className="street-address">{generellt.gata}</div>
                <span className="locality">{generellt.stad}, </span>
                <span className="postal-code">{generellt.postnummer}</span>
                <span className="company lightText">{generellt.company}</span>
                <span className="company-number lightText">{generellt.companyNumber}</span>
            </div>
            <div className="contact">
                <div className="phone-number"><a href={"tel://" + generellt.telenr.replace(/\D/g,'')}>{generellt.telenr}</a></div>
                <div className="email"><a href={"mailto://" + generellt.epost}>{generellt.epost}</a></div>
            </div>
        </div>
        <div id="face" />
        <div className="intro">
            {generellt.desc.map(content => (<p dangerouslySetInnerHTML={{__html: content}} />))}
        </div>
    </header>
)